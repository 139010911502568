import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  stringify,
  parse,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates-hourly';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ProfileBookingPanel,
  ResponsiveImage,
} from '../../components';
import { EnquiryForm, BookVettingForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import FAQMap from './FAQArrays.js';

import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ProfileListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionCancellationPolicy from './SectionCancellationPolicy';
import SectionDelivery from './SectionDelivery';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionExpertise from './SectionExpertise';
import SectionNextSteps from './SectionNextSteps';
import SectionOutcome from './SectionOutcome';
import SectionReviews from './SectionReviews';
import css from './ProfileListingPage.module.css';
import SectionMentorSuggestions from '../../components/SectionMentorSuggestions/SectionMentorSuggestions.js';
import SectionNotSure from '../../components/SectionNotSureWhichMentor/SectionNotSure.js';
import Accordeon from '../../components/Accordeon/Accordeon.js';

const dataArr = [
  {
    label: 'How does MentorME select its mentors?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'What types of mentoring sessions can I get?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'Can I choose my mentor on MentorME?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'How do I get started with a mentor on MentorME?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'How is MentorME different from other mentoring options?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'What types of side hustles can I start with MentorME?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'Is MentorME suitable for beginners?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'How can I join the MentorME community?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
];

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ProfileListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      bookVettingDialogOpen: false,
      bookValues: null,
      selectedCategory: 'Mentoring',
      marginValue: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSubmitBookVettingInfo = this.onSubmitBookVettingInfo.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onSubmitBookVettingInfo(values) {
    const bookValues = this.state.bookValues;

    this.setState({ bookVettingDialogOpen: false, bookValues: null });
    bookValues.bookVettingAnswers = values;
    this.handleSubmit(bookValues);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onScroll() {
    if (window.scrollY > 0 && window.scrollY < 730) this.setState({ marginValue: window.scrollY });
    if (window.scrollY >= 730) this.setState({ marginValue: 730 });
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      timeZone,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ProfileListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;
    const { expertise = '', delivery = '', outcome = '' } = publicData;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ProfileListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ProfileListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ProfileListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ProfileListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ProfileListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const listingPublicData = (currentListing && currentListing.attributes.publicData) || {};
    const bookVetting =
      Array.isArray(listingPublicData.bookVetting) &&
      listingPublicData.bookVetting.length > 0 &&
      listingPublicData.bookVetting[0] === 'yes';

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const openBookModal = () => {
      const { history } = this.props;
      if (isOwnListing) {
        window.scrollTo(0, 0);
      } else {
        const { pathname, search, state } = location;
        const searchString = `?${stringify({ ...parse(search), book: true })}`;
        history.push(`${pathname}${searchString}`, state);
      }
    };

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else if (bookVetting) {
        this.setState({ bookVettingDialogOpen: true, bookValues: values });
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ProfileListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ProfilePage"
        params={{ id: ensuredAuthor.id.uuid }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>{categoryLabel(categoryOptions, publicData.category)}</span>
      ) : null;
    const vettingName =
      currentUser && currentUser.attributes && currentUser.attributes.profile
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;
    const vettingEmail =
      currentUser && currentUser.attributes ? currentUser.attributes.email : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              {/* {<SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />} */}
              <div className={css.headerContainer}>
                <ResponsiveImage
                  rootClassName={css.headerImage}
                  alt={title}
                  image={
                    currentListing.images && currentListing.images.length > 0
                      ? currentListing.images[0]
                      : null
                  }
                  variants={['scaled-large']}
                />
                <div
                  style={{
                    top: this.state.marginValue + 'px',
                    position: this.state.marginValue > 0 ? 'relative' : 'inherit',
                  }}
                  className={css.bookingContainer}
                >
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    openBookModal={openBookModal}
                    description={description}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    listing={currentListing}
                    unitType={unitType}
                  />
                  <ProfileBookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    title={bookingTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    monthlyTimeSlots={monthlyTimeSlots}
                    onFetchTimeSlots={onFetchTimeSlots}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    onContactUser={this.onContactUser}
                  />
                </div>
              </div>
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  <div className={css.selectionBar}>
                    <div
                      className={
                        this.state.selectedCategory === 'Mentoring'
                          ? css.activeTab
                          : css.inactiveTab
                      }
                      onClick={() => {
                        this.setState({ ...this.state, selectedCategory: 'Mentoring' });
                      }}
                    >
                      Mentoring
                    </div>
                    <div
                      className={
                        this.state.selectedCategory === 'FAQ' ? css.activeTab : css.inactiveTab
                      }
                      onClick={() => this.setState({ ...this.state, selectedCategory: 'FAQ' })}
                    >
                      FAQ
                    </div>
                  </div>

                  {this.state.selectedCategory === 'Mentoring' ? (
                    <>
                      <SectionNextSteps
                        nextSteps={intl.formatMessage({ id: 'ProfileListingPage.nextStepsText' })}
                      />
                      <SectionExpertise expertise={expertise} />
                      <SectionDelivery delivery={delivery} />
                      <SectionOutcome outcome={outcome} />
                      <SectionCancellationPolicy
                        cancellationPolicy={intl.formatMessage(
                          { id: 'ProfileListingPage.cancellationPolicyText' },
                          {
                            fullPolicy: (
                              <NamedLink
                                name="TermsOfServicePage"
                                to={{ hash: '#order_cancellation' }}
                              >
                                <FormattedMessage id="ProfileListingPage.cancellationPolicyLink" />
                              </NamedLink>
                            ),
                          }
                        )}
                      />
                    </>
                  ) : (
                    <Accordeon radioName={'ProfileListingRadio'} dataArr={FAQMap.get(category.props.children) ?? []} />
                  )}
                </div>

                {/* {<ProfileBookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  onContactUser={this.onContactUser}
                />} */}
              </div>
              <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
              <SectionMentorSuggestions
                postArr={[]}
                title={'Similar mentors'}
                description={'Entrepreneurship mentors'}
              />
              <SectionNotSure />
            </div>
            <Modal
              id="ProfileListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
            <Modal
              id="ListingPage.bookVetting"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.bookVettingDialogOpen}
              onClose={() => this.setState({ bookVettingDialogOpen: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <BookVettingForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                initialValues={{ name: vettingName, email: vettingEmail }}
                onSubmit={this.onSubmitBookVettingInfo}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ProfileListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ProfileListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ProfileListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProfileListingPageComponent);

export default ProfileListingPage;
